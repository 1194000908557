<template>
	<div class="bankCardManage" v-if="loadStatus">
		<div class="settingwarp">
			<header>
				<div class="g-header">
					<div class="left" @click="changego">
						<van-icon name="arrow-left" color="#aaa" size="18px" />
					</div>
					<div class="middle fs-18">
						{{ type == 0 ? $t('yinhangkaguanli') : $t('xuniqianbaoguanli')}}
					</div>
					<div class="right">
						<div class="bg-icon bg-icon-setting"></div>
					</div>
				</div>
				<div class="blockHeight"></div>
			</header>
			<div class="g-content"  v-if="password==true && passwordstatus==true">
				<!-- 银行卡 -->
				<ul class="card-list" v-if="type ==0&&bank">
					<li class="card-item">
						<p>{{bank.acc}} / {{bank.acc_bank}}</p>
						<p class="cardNo">{{formatPhone(bank.acc_name)}}</p>
					</li>
				</ul>
				<!-- 虚拟钱包的卡 -->
				<ul class="card-list" v-if="type == 1&&bank">
					<li class="card-item">
						<p>{{bank.acc}}</p>
						<p class="cardNo">{{bank.acc_name}}</p>
					</li>
				</ul>
				<div class="add-card-btn" v-show="isaddbank">
					<div class="div" @click="changeAddcard(type)">
						<span>{{ type == 0?$t('tianjiayinhangka') : $t('tianjiaxuniqianbao') }}</span>
					</div>
				</div>

			</div>
			<template v-if="password==true && passwordstatus==false">
				<div class="form-wrapper is-not-set">
					<div class="lock-bg">
						<div class="paycode">
							<van-password-input
								:value="valuenum"
								:focused="showKeyboard"
								@focus="showKeyboard = true"
							/>
					
							<Keyboard ref="cusBoardss" :length="length"   :defaultVal="defaultVal" v-model:text="valuenum" v-model:showstatus="showstatus"></Keyboard>
						</div>
						<p class="c_a0a0a0 chakan"  v-if='passwordstatus == false'>
							{{$t('qingshuruchakanmaTs')}}
						</p>
					</div>
					<div class="tips" v-if='passwordstatus'>
						<span class="c_a0a0a0 chakan">{{$t('anquanmaTpsTow')}}</span>
					</div>
				</div>
			</template>
			
		</div>
	</div>
</template>
<script>
	import userApi from "@/api/user";
	import Keyboard from "@/components/keyboard/index.vue";
	export default {
		name: "BankCardManage",
		components: {
			Keyboard,
		},
		data() {
			return {
				loadStatus: true,
				type: null,
				bank: null,
				password:false,
				isaddbank:false,
				passwordstatus:false,
				showKeyboard: true,
				showstatus:true,
				valuenum: "",
				defaultVal: "",
				length: 6,
			};
		},
		watch: {
			password() {
				console.log(this.password)
				this.xianshi()
			},
			valuenum(value) {
				if (value.length === 6 && this.password == true  && this.passwordstatus == false) {
					// 调用接口
					this.showKeyboard = false;
					this.$toast.loading({
						message: this.$t('jiazaizhong'),
						forbidClick: true,
						duration: 300,
					});

					userApi
					.verbankpass(this.valuenum)
					.then((data) => {
						console.log(data.data);
						//this.PopAlert = true;
						if(data.msg == true){
							this.passwordstatus=true
							this.type = this.$route.query.type;
							userApi.payInfo(this.type).then((data) => {
								this.bank = data.data;
								if(!this.bank){
									this.isaddbank=true
								}
								this.loadStatus = true
							});
						}
					})
					.catch((err) => {
						this.$toast.fail(err.msg);
					});
				}
			}
		},
		methods: {
			formatPhone(phone) {
				//return phone.substring(0, 6) + '××××'
				return phone
			},
			changeAddcard(type) {
				if (type == 0) {
					this.$router.push({
						path: "/addBankCard",
					});
				} else {
					this.$router.push({
						path: "/addVirtualWallet",
					});
				}
			},
			changego() {
				this.$router.go(-1);
			},
			xianshi(){
				this.$nextTick(() => {
					this.$refs.cusBoardss.show();
					this.valuenum='';
				});
			},
		},
		mounted() {},
		created() {
			userApi.addressPssword().then((data) => {
				console.log(data)
				this.password=data.msg
				console.log(this.password);
				if(!this.password){
					this.$router.push({
						path: "/changebPassword",
					});
				}
			});

		},
	};
</script>
<style lang="less" scoped>
    [v-cloak] 
    { 
    display:none !important;
    }
	
	.settingwarp {
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.g-content {
		width: 100%;
		background-color: #fff;
	}

	.card-list {
		padding: 0.6rem 0.4rem 0 0.4rem;
		text-align: left;
	}

	.card-item {
		border-radius: 0.06rem;
		padding: 0.3rem;
		background: #f6f7f9;
		font-size: 0.38rem;
		border-radius: 5px;
	}

	.cardNo {
		margin-top: 0.1rem;
		color: #000;
		font-family: 'nstb';
	}

	.add-card-btn {
		width: 100%;
	}

	.add-card-btn .div {
		border: .02rem dotted #E31837;
		color: #E31837;
		font-size: .32rem;
		border-radius: 5px;
		font-family: 'nstb';
	}
	
	.paycode{
		margin-top: 0.6rem;
	}
	
	.chakan{
		margin-top: 0.2rem;
	}
</style>