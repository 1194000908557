<template>
	<div class="personal">

		<div class="mescroll p-account_scroll">
			<div class="scroll-content">
				<div class="cont-head">
					<div class="user-head">
						<!-- <div class="jpm_logoa"><img src="@/assets/image/jpmlogol.svg" /></div> -->

						<div class="user-avatar">
							<img src="@/assets/image/logo.svg" class="avatar" alt="" />
						</div>

						<div class="grade-box">
							<div>{{ $t('huiyuanname') }} <span>{{ user.username }}</span><p>{{ $t('welcomeback') }}</p></div>
							<div>{{ $t('huiyuanquanyi') }}｜<span>{{ user.id }}</span></div>
						</div>

					</div>
				</div>
				<div class="cont-box">
					<div class="asset">
						<div class="asset-item">
							<span>{{ $t('zongzichan') }} <font>{{ $t('qfh') }}</font></span>
							<span>{{ user.money }}</span>
						</div>
						<div class="asset-item">
							<span>{{ $t('zongzichan') }} <font>{{ $t('qfh') }}</font></span>
							<span>{{ user.money }}</span>
						</div>
						<div class="asset-item">
							<span>{{ $t('zuoriyingli') }} <font>{{ $t('qfh') }}</font></span>
							<span>{{ user.today_profit }}</span>
						</div>
						
						<!-- <div class="asset-item">
                    <span>{{ $t('chucunjin') }}</span>
                    <span>{{ user.score }}</span>
                  </div> -->
						<div class="asset-item">
							<span>{{ $t('jinriyingli') }} <font>{{ $t('qfh') }}</font></span>
							<span>{{ user.profit }}</span>
						</div>
					</div>
					<ul class="tx-box">
						<li class="tx" @click="changeRecharge">
							<img src="@/assets/image/cunru.png" class="icon" alt="" />
							<span class="text">{{ $t('cunru') }}</span>
						</li>
						<li class="tx" @click="changeWithdraw">
							<img src="@/assets/image/zhuanchu.png" class="icon" alt="" />
							<span class="text">{{ $t('zhuanchu') }}</span>
						</li>
						<!-- <li class="tx" @click="Savings">
							<img src="@/assets/image/zhuanchu.png" class="icon" alt="" />
							<span class="text">{{ $t('savings') }}</span>
						</li> -->
					</ul>
					<ul class="list-box">
						<li class="list" @click="changestting(2)">
							<div class="list-text">{{ $t('zhanghuxinxni') }}</div>
							<img src="@/assets/image/you.png" class="arrow" alt="" />
						</li>
						<!-- <li class="list" @click="smrz()">
								<div class="list-text">{{ $t('smrz') }}</div>
								<img src="@/assets/image/you.png" class="arrow" alt="" />
							</li> -->
						<li class="list" @click="changeBet">
							<div class="list-text">{{ $t('lishizhangdan') }}</div>
							<img src="@/assets/image/you.png" class="arrow" alt="" />
						</li>
						<li class="list" @click="Savingslist">
							<div class="list-text">{{ $t('jjzhangdan') }}</div>
							<img src="@/assets/image/you.png" class="arrow" alt="" />
						</li>
						<li class="list" @click="changeCapitalDetail">
							<div class="list-text">{{ $t('zijinminxi') }}</div>
							<img src="@/assets/image/you.png" class="arrow" alt="" />
						</li>
						<!-- <li class="list">
                    <div class="list-text">{{ $t('renwuzhongxin') }}</div>
                    <img src="@/assets/image/you.png" class="arrow" alt="" />
                  </li> -->
						<!-- <li class="list" @click="changeNotice">
                    <div class="list-text">{{ $t('xiaoxigonggao') }}</div>
                    <img src="@/assets/image/you.png" class="arrow" alt="" />
                  </li> -->
						<!-- <li class="list" @click="changeShareFriend">
							<div class="list-text">{{ $t('tuijianfenxiang') }}</div>
							<img src="@/assets/image/you.png" class="arrow" alt="" />
						</li> -->
						<li class="list" @click="changeabout">
							<div class="list-text">{{ $t('guanyuwomen') }}</div>
							<img src="@/assets/image/you.png" class="arrow" alt="" />
						</li>
						<li class="list" @click="changeLanguage">
							<div class="list-text">{{ $t('yuyanshezhi') }}</div>
							<img src="@/assets/image/you.png" class="arrow" alt="" />
						</li>
						<!-- <li class="list" @click="changeActionCenter">
                    <div class="list-text">{{ $t('huodongzhongxin') }}</div>
                    <img src="@/assets/image/you.png" class="arrow" alt="" />
                  </li> -->
					</ul>
					<!-- 退出登录 -->
					<div class="button-box" @click="changelogin">
						<van-button type="primary" block round>{{ $t('tuichudenglu') }}</van-button>
					</div>
				</div>
			</div>
		</div>
		<!-- </van-pull-refresh> -->
		<!-- </div> -->
		<tab-bar></tab-bar>
	</div>
</template>
<script>
	import TabBar from "@/components/tabbar/TabBar.vue";
	import userApi from "@/api/user";
	import storeAction from "@/store/typed-actions";
	export default {
		name: "Personal",
		components: {
			TabBar,
		},
		data() {
			return {
				isLoading: false,
				user: "",
			};
		},
		methods: {
			onRefresh() {
				this.$toast.loading({
					message: this.$t('jiazaizhong'),
					forbidClick: true,
					duration: 0,
				});
				userApi.userInfo().then((data) => {
					console.log(data.msg);
					this.user = data.msg;
					storeAction.setCurrentUserMoney(data.msg.money);
					storeAction.setCurrentUserGroup_id(data.msg.group_id);
					storeAction.setsessionStorage("money", data.msg.money);
					storeAction.setsessionStorage("group_id", data.msg.group_id);
					storeAction.setsessionStorage("idcard", data.msg.idcard);
					storeAction.setCurrentUserusercrud(data.msg.idcard);
					this.isLoading = false;
					this.$toast.clear();
				});
			},
			changestting(type) {
				this.$router.push({
					path: "/setting",
					query: {
						type: type,
					},
				});
			},
			smrz() {
				this.$router.push({
					path: "/smrz"
				});
			},
			changebaseinfo() {
				this.$router.push({
					path: "/baseinfo",
					query: {
						name: this.user.username,
					},
				});
			},
			changeActionCenter() {
				this.$router.push({
					path: "/actionCenter",
				});
			},
			changeShareFriend() {
				this.$router.push({
					path: "/shareFriend",
				});
			},
			changeabout() {
				this.$router.push({
					path: "/about",
				});
			},
			changeNotice() {
				this.$router.push({
					path: "/Notice",
				});
			},
			changeBet() {
				this.$router.push({
					path: "/BetRecord",
				});
			},
			Savingslist(){
				this.$router.push({
					path: "/Savingslist",
				});
			},
			changeLanguage() {
				this.$router.push({
					path: "/language",
				});
			},
			changeCapitalDetail() {
				this.$router.push({
					path: "/CapitalDetail",
				});
			},
			changeWithdraw() {
				this.$router.push({
					path: "/Withdraw",
					query: {
						group_id: this.user.group_id,
					},
				});
			},
			Savings() {
				this.$router.push({
					path: "/Savings",
				});
			},
			changeRecharge() {
				this.$router.push({
					path: "/Recharge",
					query: {
						group_id: this.user.group_id,
					},
				});
			},
			changego() {
				this.$router.go(-1);
			},
			changelogin() {
				this.$toast.loading({
					message: this.$t('jiazaizhong'),
					forbidClick: true,
					duration: 0,
				});
				this.$router.push({
					path: "/login",
				});
				this.$toast.clear();
			},
		},
		created() {
			this.onRefresh();
		},
	};
</script>
<style lang="less" scoped>
	.personal {
		height: 100%;
	}

	.p-account {
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		background: #4bb3e8;
		height: 100%;
	}

	.bg-icon-setting {
		background: url("../assets/image/shezhi.png");
		background-size: 100%;
	}

	.bg-icon {
		width: 0.6rem;
		height: 0.6rem;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}

	.mescroll {
		width: 100%;
		height: 100%;
		overflow-y: auto;
		-webkit-box-flex: 1;
		flex: 1;
	}

	.scroll-content {
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-bottom: 2rem;
		padding-bottom: calc(2rem + constant(safe-area-inset-bottom));
		padding-bottom: calc(2rem + env(safe-area-inset-bottom));
	}

	.cont-head {
		padding: 0.5rem 0.6rem 0.4rem .6rem;
		position: relative;
		overflow: hidden;
	}

	.user-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.jpm_logoa {
		width: 20%;
		position: absolute;
		top: 20px;
		right: 20px;

		img {
			width: 100%;
		}
	}

	.user-avatar {
		width: 50px;
		height: 50px;
		background: #f6f7f9;
		border-radius: 50%;

		img {
			width: 75%;
			margin-top: 18px;
		}
	}

	.avatar {
		// height: 0.9rem;
	}

	.grade-box {
		width: calc(100% - 55px);
		color: #ccc;
		text-align: left;
		margin-left: 15px;
		font-size: 0.3rem;
		line-height: 0.44rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		
		span{
			font-family: 'nstb';
			color: #aaa;
			text-transform: uppercase;
		}
	}

	.member-benefit {
		margin: 0.4rem 0 0.2rem 0;
		display: inline-block;

		span {
			color: #fff;
			font-size: 0.35rem;
		}
	}

	.icon-vip-equity {
		width: 0.5rem;
		height: 0.4rem;
		margin-right: 0.15rem;
	}

	.nickName {
		font-size: 0.35rem;
		color: #fff;
	}

	.icon-vip-grade {
		margin-left: 5px;
		width: 0.5rem;
	}

	.cont-box {
		flex: 1;
	}

	.asset {
		// display: flex;
		// padding: 0.3rem 0.3rem 0.2rem 0.3rem;
		text-align: center;
		// background: #e31837;
		width: calc(100% - 1.2rem);
		margin: 0 auto 6px auto;
		// border-radius: 8px 8px 0 0;
	}

	.asset-item:nth-child(2n+2) {
		margin-left: 2px;
	}

	.asset-item {
		// -webkit-box-flex: 1;
		// -ms-flex: 1;
		// flex: 1;
		// position: relative;
		// border-right: .02rem solid rgba(255, 255, 255, 0.3);
		// display: flex;
		// -webkit-box-orient: vertical;
		// -webkit-box-direction: normal;
		// -ms-flex-direction: column;
		// flex-direction: column;
		// -webkit-box-pack: center;
		// -ms-flex-pack: center;
		// justify-content: center;
		// padding: 0.05rem 0;
		display: inline-block;
		width: calc(50% - 1px);
		background: #f6f7f9;
		// background: #e31837;
		padding: 0.5rem 0;
		margin-bottom: 2px;
		border-radius: 5px;

		span {
			font-size: 0.28rem;
			line-height: .2rem;
			color: #ccc;
			display: block;
			font {
				font-size: .24rem;
				font-family: 'nst';
			}
		}

		span:nth-child(2) {
			font-size: 0.4rem;
			line-height: 0.2rem;
			color: #000;
			font-family: 'nstb';
			margin-top: 10px;
		}
	}

	.tx-box {
		padding: 0;
		// margin-top: 10px;
		width: calc(100% - 1.2rem);
		margin: 0 auto;
	}

	.tx {
		width: calc(50% - 2px);
		padding: 0.2rem 0;
		display: inline-block;
		background: #e31837;
		border-radius: 3px;
	}
	
	.tx:nth-child(2) {
		margin-left: 2px;
	}

	.tx img {
		width: 16px;
		height: 16px;
	}

	.text {
		margin-left: 0.2rem;
		font-size: 0.32rem;
		color: #fff;
		font-family: 'nstb';
	}

	.list-box {
		margin: 10px 0 0 0;
		padding: 0 .6rem 0 .6rem;
	}

	.list {
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: .28rem 0 .28rem 0;
		border-bottom: .02rem solid #f5f5f5;
	}
	
	.list:last-child{
		border-bottom: none;
	}

	.list-text {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		text-align: left;
		font-size: 0.3rem;
	}

	.arrow {
		width: 0.15rem;
		height: 0.3rem;
	}

	.p-account .list-box .list:last-child {
		border-bottom: none;
	}

	.rightico {
		position: absolute;
		top: 0.2rem;
	}
	
	.button-box {
		width: calc(100% - 1.2rem);
		margin: 10px auto 0 auto;
	
		button {
			color: #fff;
			background-color: #444;
			border: none;
			height: 35px;
			line-height: 35px;
			font-family: 'nstb';
		}
	}
	
	/deep/ .van-button--round{
		border-radius: 5px;
	}
</style>