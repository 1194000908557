<template>
	<div class="login">

		<div class="close-btn" @click="changeRouteHome">
			<i class="icon-x"></i>
		</div>

		<div class="login-head-c">
			<img src="@/assets/image/logo.svg" alt="" />
		</div>
		
		<!-- <div class="login-bg">
			<img src="@/assets/image/loginbg.jpg" alt="" />
		</div> -->

		<div class="g-content">
			<div class="lrtitle">{{$t('dengluzhanghao')}}</div>

			<van-field :placeholder="$t('plusername')" v-model="username" name="username">
				<template #left-icon>
					<img src="@/assets/image/user.png" class="left-form-icon" alt="" />
				</template>
			</van-field>

			<van-field v-model="password" :type="switchPassType ? 'text' : 'password'" name="password"
				:right-icon="switchPassType ? 'eye' : 'closed-eye'" :placeholder="$t('plpassword')"
				@click-right-icon="switchPassType = !switchPassType">
				<template #left-icon>
					<img src="@/assets/image/pwd.png" class="left-form-icon" alt="" />
				</template>
			</van-field>

			<van-cell>
				<template #title>
					<van-checkbox v-model="checked"><span class="c_a0a0a0">{{ $t("jizhumima") }}</span>
					</van-checkbox>
				</template>
				<template #value>
					<span class="c_a0a0a0" @click="changePWDho">{{ $t("wangjimima") }}?</span>
				</template>
			</van-cell>


			<van-button type="primary" round block class="Btnlogin" @click="changeHome">{{ $t("denglu") }}</van-button>


			<div class="has-acoount">
				<span>{{ $t("meiyouzhanghao") }}</span>
				<span class="btn-tologin" @click="changeRegister">{{$t("zhucezhanghao")}}</span>
			</div>


		</div>
		<!-- <p class="app-version">{{ $t("dangqianbanben") }} 23.0812.04</p> -->
		<alert-pop :alertType="3" :Tips="TipsTxt" v-if="PopAlert" @showAletfalse="showAletfalse"></alert-pop>
	</div>
</template>

<script>
	// @ is an alias to /src
	import loginApi from "@/api/login";
	import storeAction from "@/store/typed-actions";
	import AlertPop from "@/components/alert/AlertPop.vue";
	import {
		ref
	} from "vue";
	export default {
		name: "Login",
		components: {
			AlertPop,
		},
		setup() {
			const loginPassword = ref('');
			const switchPassType = ref(false);
			return {
				loginPassword,
				switchPassType,
			};
		},
		data() {
			return {
				checked: true,
				username: "",
				password: "",
				PopAlert: false,
				TipsTxt: this.$t('Tips.wjmm'),
			};
		},
		methods: {
			changeRegister() {
				this.$router.push({
					path: "/register",
				});
			},
			changeHome() {
				if (!this.username) {
					this.$toast(this.$t('plusername'));
				} else if (!this.password) {
					this.$toast(this.$t('plpassword'));
				} else {
					if (this.checked) {
						this.setCookie(this.username, this.password, 14);
					} else {
						this.setCookie("", "", -1);
					}
					loginApi
						.login(this.username, this.password)
						.then((data) => {
							console.log(data.data, this.$t('Tips.dlcg'));
							storeAction.loginDone(data.data);
						})
						.catch((err) => {
							this.$toast.clear();
							this.$toast.fail(this.$t(err.msg));
						});
				}
			},

			// 游客注册
			changeTourist() {
				if (this.checked) {
					let username =
						this.$t("youke") + Math.floor(new Date(new Date()).getTime() / 1000);
					loginApi
						.register(username, this.randomCoding(), "2", "")
						.then((data) => {
							console.log(data.data, "游客注册成功");
							storeAction.loginDone(data.data);
						})
						.catch((err) => {
							this.$toast.clear();
							this.$toast.fail(this.$t(err.msg));
						});
					this.setCookie(username, "", 14);
				} else {
					this.setCookie("", "", -1);
				}
			},
			// 忘记密码
			changePWDho() {
				this.PopAlert = true;
			},
			showAletfalse() {
				this.PopAlert = false;
			},
			changeRouteHome() {
				let that = this;
				storeAction.clearCurrentUserId();
				storeAction.clearToken();
				storeAction.delsessionStorage("id");
				storeAction.delsessionStorage("userId");
				storeAction.delsessionStorage("money");
				storeAction.delsessionStorage("idcard");
				storeAction.delsessionStorage("group_id");
				storeAction.setCurrentUserMoney(null);
				this.$toast.loading({
					message: this.$t('jiazaizhong'),
					forbidClick: true,
					duration: 500,
					onClose() {
						that.$router.push(`/`);
					},
				});
			},
			// 随机生成字母
			randomCoding() {
				//创建26个字母数组
				let arr = [
					"A",
					"B",
					"C",
					"D",
					"E",
					"F",
					"G",
					"H",
					"I",
					"J",
					"K",
					"L",
					"M",
					"N",
					"O",
					"P",
					"Q",
					"R",
					"S",
					"T",
					"U",
					"V",
					"W",
					"X",
					"Y",
					"Z",
				];
				let idvalue = "";
				for (let i = 0; i < 6; i++) {
					idvalue += arr[Math.floor(Math.random() * 26)];
				}
				console.log(idvalue, "idvalue");
				return idvalue;
			},
			setCookie(name, pwd, exdays) {
				var exdate = new Date(); // 获取时间
				exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); // 保存的天数
				// 字符串拼接cookie
				window.document.cookie =
					"userName" + "=" + name + ";path=/;expires=" + exdate.toGMTString();
				window.document.cookie =
					"userPwd" + "=" + pwd + ";path=/;expires=" + exdate.toGMTString();
			},
			// 读取cookie 将用户名和密码回显到input框中
			getCookie() {
				if (document.cookie.length > 0) {
					var arr = document.cookie.split("; "); // 这里显示的格式需要切割一下自己可输出看下
					for (var i = 0; i < arr.length; i++) {
						var arr2 = arr[i].split("="); // 再次切割
						// 判断查找相对应的值
						if (arr2[0] === "userName") {
							this.username = arr2[1]; // 保存到保存数据的地方
							// 其中unescape() 方法是将字符串进行编码，escape()方法是将字符串进行解码。
						} else if (arr2[0] === "userPwd") {
							this.password = arr2[1];
						}
					}
				}
			},
		},
		created() {
			this.getCookie();
		},
	};
</script>
<style lang="less" scoped>
	.login {
		// background: rgb(40, 195, 100);
		// background: linear-gradient(160deg, rgba(40, 195, 100, 1) 0%, rgba(0, 150, 60, 1) 40%);
		background: url("../assets/image/loginbg.svg");
		background-size: 90%;
		background-position: 2.5rem 1.4rem;
		background-repeat: no-repeat;
	}

	.close-btn {
		position: absolute;
		z-index: 1;
		right: .6rem;
		top: .5rem;
	}

	.icon-x {
		display: inline-block;
		width: 0.5rem;
		height: 0.07rem;
		background: #aaa;
		line-height: 0;
		font-size: 0;
		vertical-align: middle;
		transform: rotate(45deg);
	}

	.icon-x::after {
		content: "";
		display: block;
		width: 0.5rem;
		height: 0.07rem;
		background: #aaa;
		transform: rotate(-90deg);
	}

	.g-content {
		position: absolute;
		bottom: 3vh;
		width: 100%;
		padding: 0 .6rem;
		// overflow: scroll;
	}

	.login-head-c {
		position: absolute;
		top: .3rem;
		text-align: left;
		width: 100%;
		padding: 0 .6rem;
		box-sizing: border-box;

		img {
			width: 100px;
			display: block;
		}
	}
	
	.login-bg {
		margin-top: 3.5rem;
		img{
			width: 90%;
		}
	}

	// .login-body {
	// 	margin-top: 5.7rem;
	// 	padding: 0 1.68rem;
	// }

	.lrtitle {
		width: 100%;
		text-align: left;
		margin-bottom: 20px;
		font-family: 'nstb';
		color: #000;
		font-size: .5rem;
	}

	.van-cell-group {
		background: unset;
		position: unset;
		border: none;

		input {}
	}

	.van-cell {
		position: relative;
		display: flex;
		box-sizing: border-box;
		width: 100%;
		padding: 15px 0;
		overflow: hidden;
		color: unset;
		font-size: .32rem;
		line-height: .32rem;
		background-color: unset;
	}

	.van-field::after {
		position: absolute;
		box-sizing: border-box;
		content: " ";
		pointer-events: none;
		right: 0;
		bottom: 0;
		left: 0;
		border-bottom: .02rem solid #ddd !important;
		-webkit-transform: scaleY(0.5);
		transform: scaleY(0.5);
	}

	/deep/.van-checkbox__icon .van-icon {
		border: 1px solid #aaa;
	}

	/deep/.van-checkbox__icon--checked .van-icon {
		background-color: #E31837;
		border: 1px solid #E31837;
		// color: #fff;
	}

	.left-form-icon {
		width: 20px;
	}

	.c_a0a0a0 {
		color: #aaa;
	}

	.Btnlogin {
		// color: #fff;
		background: #444444;
		// background: linear-gradient(160deg, rgba(0, 150, 60, 1) 0%, rgba(40, 195, 100, 1) 40%);
		border: none;
		font-family: 'nstb';
		margin-bottom: 10px;
	}
	
	/deep/ .van-button--round{
		border-radius: 5px;
	}

	// .TxtBtn {
	// 	background-color: #0ab45b;
	// 	border: 1px solid #0ab45b;
	// }

	.content {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.open-visitor {
		justify-content: space-around;
	}

	.app-version {
		text-align: center;
		position: absolute;
		width: 100%;
		bottom: 0.25rem;
		color: rgba(255, 255, 255, 0.2);
		font-size: 0.26rem;
	}

	.van-cell::after {
		position: absolute;
		box-sizing: border-box;
		content: " ";
		pointer-events: none;
		right: 0;
		bottom: 0;
		left: 0;
		border-bottom: none;
	}

	.has-acoount {}

	.btn-tologin {
		margin-left: 5px;
		color: #E31837;
		font-family: 'nstb';
	}
</style>